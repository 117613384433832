import Vue from './config'
import newsFilters from '../vue-components/newsFilters'

if (document.getElementById('vue-newsitem')) {
    new Vue({
        el: '#vue-newsitem',
        components: {
            newsFilters
        },
        data: {
            teamId: 0,
            sportId: 0,
            catId: 0,
        },
        computed: {
        },
        methods: {
            doSearch(model) {
                var qs = []
                if (model.catId != 0) qs.push(`catId=${model.catId}`)
                if (model.teamId != 0) qs.push(`teamId=${model.teamId}`)
                if (model.sportId != 0) qs.push(`sportId=${model.sportId}`)
                var qss = '?' + qs.join('&')
                window.location.href = model.url + qss
            },
        },
        mounted() {
        }
    })
}