import LazyLoad from 'vanilla-lazyload'
import Delaunay from './triangles'

window.lazyLoad = new LazyLoad({
    elements_selector: '[data-src], [data-srcset], [data-bg]'
})

Delaunay.init('.triangles')

document.querySelectorAll('.accordion__item').forEach(el => {
    el.addEventListener('click', function (e) {
        el.querySelector('.accordion__body').classList.toggle('open')
    })
})

document.querySelectorAll('.locations__head').forEach(el => {
    el.addEventListener('click', function (e) {
        el.parentElement.querySelector('.locations__body').classList.toggle('open')
    })
})

document.querySelectorAll('.map__container').forEach(el => {
    var lat = parseFloat(el.dataset.lat)
    var lon = parseFloat(el.dataset.lon)
    var latlng = { lat: lat, lng: lon }
    var options = {
        zoom: 15,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        panControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP
        }

    }
    var map = new google.maps.Map(el, options)
    var marker = new google.maps.Marker({
        position: latlng,
        map: map,
    })
})

document.querySelectorAll('.main video').forEach(el => {
    if (window.innerWidth >= 1024) {
        el.play()
    }
})

document.querySelectorAll('.counter').forEach(el => {
    var countDownDate = new Date('Jul 8, 2023 19:00:00').getTime()
    function update(el) {
        var now = new Date().getTime()
        var distance = countDownDate - now
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        el.querySelector('[data-field="days"]').innerHTML = days
        el.querySelector('[data-field="hours"]').innerHTML = hours
        el.querySelector('[data-field="mins"]').innerHTML = minutes
        el.querySelector('[data-field="secs"]').innerHTML = seconds
    }
    update(el)
    window.setInterval(function () { update(el) }, 1000)
})