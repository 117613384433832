import Vue from './config'
import Delaunay from '../js/triangles'

if (document.getElementById('vue-team')) {
    new Vue({
        el: '#vue-team',
        components: {
        },
        data: {
            tab: 0,
            offset: 0,
            local: new Date(),
            booster: null,
        },
        watch: {
            tab() {
                if (this.tab == 0)
                    this.$nextTick(function () {
                        Delaunay.init('.triangles')
                    })
            }
        },
        computed: {
            foreign() {
                return new Date(this.local.getFullYear(), this.local.getMonth(), this.local.getDate(),
                    this.local.getHours() + this.offset, this.local.getMinutes(), this.local.getSeconds())
            },
            foreignH() {
                return this.foreign.getHours()
            },
            foreignM() {
                return this.foreign.getMinutes()
            },
            foreignS() {
                return this.foreign.getSeconds()
            }
        },
        methods: {
            zeroPad(i) {
                return i <= 9 ? '0' + i : i
            },
            getTransformH() {
                var x = (this.foreignH * 118.4) - 90
                return `transform: translatex(-${x}px)`
            },
            getTransformM() {
                var x = this.foreignM * 118.4
                return `transform: translatex(-${x}px)`
            },
            getTransformS() {
                var x = (this.foreignS * 32.25) + 35
                return `transform: translatex(-${x}px)`
            },
        },
        mounted() {
            var vm = this
            vm.offset = parseInt(vm.$el.dataset.offset)
            window.setInterval(function () {
                vm.local = new Date()
            }, 1000)
            
            Delaunay.init('.vue-triangles')
        }
    })
}