import Vue from './config'

if (document.getElementById('vue-generic')) {
    new Vue({
        el: '#vue-generic',
        components: {
        },
        data: {
            overlay: 0
        },
        computed: {
        },
        methods: {
            closeOverlay(e) {
                this.overlay = 0
            }
        },
        mounted() {
        }
    })
}