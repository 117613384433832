var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "sort" }, [
    _c("div", { staticClass: "sort__container container container--large" }, [
      _c("div", { staticClass: "sort__title" }, [
        _c("a", { attrs: { href: _vm.url } }, [_vm._v("LATEST NEWS")]),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "sort__toggle" }, [_vm._v("Filters")]),
      _vm._v(" "),
      _c("div", { staticClass: "sort__group" }, [
        _c("div", { staticClass: "sort__field" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.teamId,
                  expression: "teamId",
                },
              ],
              staticClass: "select",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.teamId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: 0 } }, [_vm._v("TEAM")]),
              _vm._v(" "),
              _vm._l(_vm.teams, function (i) {
                return _c("option", { domProps: { value: i.id } }, [
                  _vm._v(_vm._s(i.name)),
                ])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sort__field" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.catId,
                  expression: "catId",
                },
              ],
              staticClass: "select",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.catId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: 0 } }, [_vm._v("CATEGORY")]),
              _vm._v(" "),
              _vm._l(_vm.cats, function (c) {
                return _c("option", { domProps: { value: c.id } }, [
                  _vm._v(_vm._s(c.name)),
                ])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sort__field" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sportId,
                  expression: "sportId",
                },
              ],
              staticClass: "select",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.sportId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: 0 } }, [_vm._v("SPORT")]),
              _vm._v(" "),
              _vm._l(_vm.sports, function (s) {
                return _c("option", { domProps: { value: s.id } }, [
                  _vm._v(_vm._s(s.name)),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }