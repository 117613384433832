import Vue from './config'
import newsFilters from '../vue-components/newsFilters'

if (document.getElementById('vue-newslist')) {
    new Vue({
        el: '#vue-newslist',
        components: {
            newsFilters
        },
        data: {
            items: [],
            page: 0,
            perPage: 6,
        },
        computed: {
            displayPage() {
                return this.page + 1
            },
            pages() {
                return Math.ceil(this.items.length / this.perPage)
            },
            curPage() {
                return this.items.slice(this.page * this.perPage, (this.page * this.perPage) + this.perPage)
            },
        },
        methods: {
            doSearch(model) {
                var vm = this
                vm.doPost('/umbraco/api/news/search', { catId: model.catId, teamId: model.teamId, sportId: model.sportId }, function (data) {
                    vm.items = data
                })
            }
        },
        mounted() {            
        }
    })
}