import Vue from './config'

if (document.getElementById('vue-venues')) {
    new Vue({
        el: '#vue-venues',
        data: {
            tab: 0,
            venues: venues,
            sportId: 0,
            map: null,
            infoWindow: null,
            markers: []
        },
        computed: {
            filtered() {
                var vm = this
                return vm.sportId == 0 ? vm.venues : vm.venues.filter(v => v.Sports.filter(s => s.Id == vm.sportId).length)
            }
        },
        watch: {
            tab() {
                if (this.map == null)
                    this.$nextTick(function () {
                        this.initMap()
                    })
            },
            sportId() {
                if (this.map != null) this.addPins()
            }
        },
        methods: {
            initMap() {
                var options = {
                    zoom: 12,
                    maxZoom: 17,
                    minZoom: 12,
                    center: { lat: 49.46, lng: -2.58 },
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                    panControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    streetViewControl: false,
                    styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
                }
                this.map = new google.maps.Map(document.querySelector('.map'), options)
                this.infoWindow = new google.maps.InfoWindow({
                    content: '',
                    maxWidth: 280,
                    padding: 0
                })
                this.addPins()
            },
            addPins() {
                var vm = this
                this.markers.forEach(m => { m.setMap(null) })
                this.markers = []
                var bounds = new google.maps.LatLngBounds()
                this.filtered.forEach(v => {
                    var m = vm.createPin(v)
                    bounds.extend(m.position)
                    vm.markers.push(m)
                })
                if (vm.markers.length) vm.map.fitBounds(bounds)
            },
            createPin(v) {
                var vm = this
                var imgTag = `<img src="${v.PopImage}" />`
                var content = `<div class="map-popup">${imgTag}<h4>${v.Name}</h4><a href="${v.Url}" class="button">View</a></div>`
                var m = new google.maps.Marker({
                    position: new google.maps.LatLng(v.Location.lat, v.Location.lng),
                    icon: {
                        path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                        fillColor: '#009933',
                        fillOpacity: 1,
                        anchor: new google.maps.Point(0, 0),
                        scale: .6
                    },
                    title: v.Name,
                    content: content,
                    map: vm.map,
                })
                google.maps.event.addListener(m, 'click', function () {
                    vm.infoWindow.setContent(this.content)
                    vm.infoWindow.open(vm.map, this)
                })
                return m
            },
        },
        mounted() {
        }
    })
}