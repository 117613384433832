var body = document.body;
menuToggles = document.querySelectorAll(".header__burger, .header__link--search"),
    menu = document.querySelector(".menu"),
    close = document.querySelector(".menu__close");

menuToggles.forEach(el => {
    el.onclick = function () {
        body.classList.add("no-scroll");
        menu.classList.add("visible");
    };
})

close.onclick = function () {
    body.classList.remove("no-scroll");
    menu.classList.remove("visible");
};

// menu
var back = menu.querySelector(".menu__back"),
    sidebar = menu.querySelector(".menu__sidebar"),
    heads = menu.querySelectorAll(".menu__head"),
    container = menu.querySelector(".menu__container"),
    subs = menu.querySelectorAll(".menu__sub");

heads.forEach(function (head) {
    var dataHead = head.dataset.head;

    if (dataHead) {
        head.onclick = function (e) {
            e.preventDefault();

            heads.forEach(function (h) {
                h.classList.remove("active");
            });
            subs.forEach(function (s) {
                s.classList.remove("visible");
            });

            menu.classList.add("open-sub");
            head.classList.add("active");

            subs.forEach(function (sub) {
                var dataSub = sub.dataset.sub;
                if (dataHead === dataSub) {
                    sub.classList.add("visible");
                }
            });
        }
    }
});

back.onclick = function () {
    menu.classList.remove("open-sub");

    heads.forEach(function (h) {
        h.classList.remove("active");
    });
    subs.forEach(function (s) {
        s.classList.remove("visible");
    });
}