import Vue from './config'

if (document.getElementById('vue-sportevent')) {
    new Vue({
        el: '#vue-sportevent',
        components: {
        },
        data: {
            pageId: 0,
            scores:[],
            updates: [],
        },
        methods: {
            poll() {
                var vm = this
                vm.doPost('/umbraco/api/sportevent/liveupdates', vm.pageId, function (data) {
                    vm.scores = data.scores
                    vm.updates = data.updates
                })
            }
        },
        mounted() {
            this.pageId = parseInt(this.$el.dataset.id)
            this.poll()
            window.setInterval(this.poll, 10000)
        }
    })
}