import Vue from 'vue'

var dev = true
Vue.config.silent = !dev
Vue.config.devtools = dev


Vue.directive('clickout', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})

Vue.mixin({
    data() {
        return {
            querystringObject: {}
        }
    },
    methods: {
        doPost(url, argsObj, callback) {
            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(argsObj)
            }).then((response) => {
                response.json().then((data) => {
                    callback(data)
                })
            })
        },
        doGet(url, callback) {
            fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }).then((response) => {
                response.json().then((data) => {
                    callback(data)
                })
            })
        },
        getQuerystringObject() {
            var search = window.location.search.toLowerCase()
            var urlSearchParams = new URLSearchParams(search)
            return Object.fromEntries(urlSearchParams.entries())
        },
    },
    mounted() {
        this.querystringObject = this.getQuerystringObject()
    },
    updated() {
        window.lazyLoad.update()
    }
})


export default Vue