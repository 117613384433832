<template>
    <section class="sort">
        <div class="sort__container container container--large">
            <div class="sort__title"><a :href="url">LATEST NEWS</a></div>
            <button class="sort__toggle">Filters</button>
            <div class="sort__group">
                <div class="sort__field">
                    <select class="select" v-model="teamId">
                        <option :value="0">TEAM</option>
                        <option v-for="i in teams" :value="i.id">{{i.name}}</option>
                    </select>
                </div>
                <div class="sort__field">
                    <select class="select" v-model="catId">
                        <option :value="0">CATEGORY</option>
                        <option v-for="c in cats" :value="c.id">{{c.name}}</option>
                    </select>
                </div>
                <div class="sort__field">
                    <select class="select" v-model="sportId">
                        <option :value="0">SPORT</option>
                        <option v-for="s in sports" :value="s.id">{{s.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: {
        },
        data() {
            return {
                teams: [],
                teamId: 0,
                sports: [],
                sportId: 0,
                cats: [],
                catId: 0,
            }
        },
        props: {
            url: String,
            isListing: Boolean
        },
        watch: {
            catId() { this.emitChange() },
            sportId() { this.emitChange() },
            teamId() { this.emitChange() },
        },
        methods: {
            emitChange() {
                var vm = this
                this.$emit('changed', { url: vm.url, teamId: vm.teamId, sportId: vm.sportId, catId: vm.catId })
            }
        },
        mounted() {
            var vm = this
            vm.doPost('/umbraco/api/news/getfilters', null, (data) => {
                vm.teams = data.teams
                vm.sports = data.sports
                vm.cats = data.cats
            })
            if (vm.querystringObject.catid) vm.catId = parseInt(vm.querystringObject.catid)
            if (vm.querystringObject.sportid) vm.sportId = parseInt(vm.querystringObject.sportid)
            if (vm.querystringObject.teamid) vm.teamId = parseInt(vm.querystringObject.teamid)

            if (vm.catId > 0 || vm.sportId > 0 || vm.teamId > 0 || vm.isListing) this.emitChange()
        }
    }
</script>