import Vue from './config'

if (document.getElementById('vue-programme')) {
    new Vue({
        el: '#vue-programme',
        data: {
            curDate: { DateDisplay: '', Sports: [] },
            sportId: 0,
            teamId: 0,
            schedule: schedule,
            sports: sports,
            teams: teams,
            resultsUrl: resultsUrl,
            venues: venues,
        },
        methods: {
            getCompetitorName(resultRow) {
                if (resultRow.Competitor == null) return resultRow.Island.IslandDesc
                return resultRow.Competitor.FullName
            },
            getResultsUrl(resultRow) {
                return this.resultsUrl + `/sports/${resultRow.Event.Sport.SportCode}/default.aspx?sportid=${resultRow.Event.Sport.SportID}&eventid=${resultRow.Event.EventID}`
            },
            getVenueUrl(resultRow) {
                var v = this.venues.filter(v => v.ApiId == resultRow.Venue.VenueID)
                if (v.length == 1) return v[0].Url
                return false
            },
        },
        computed: {
            filteredSports() {
                var vm = this
                return vm.curDate.Sports.filter(s => vm.sportId == 0 || s.Id == vm.sportId)
            },
        },
        mounted() {
            this.curDate = this.schedule[0]
        }
    })
}