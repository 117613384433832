import Vue from './config'

if (document.getElementById('vue-search')) {
    new Vue({
        el: '#vue-search',
        components: {
        },
        data: {
            query: '',
            results: [],
            searched: false,
        },
        computed: {
            noResults() {
                return this.searched && this.query.length >= 3
                    && this.results.length == 0
            },
        },
        methods: {
            doSearch() {
                var vm = this
                vm.searched = true
                vm.doPost('/umbraco/api/search/search', vm.query, (data) => {
                    vm.results = data
                })
            },
        },
        mounted() {
            var vm = this
            if (vm.querystringObject.query) vm.query = vm.querystringObject.query
            vm.doSearch()
        }
    })
}