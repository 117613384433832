import ScrollBooster from 'scrollbooster'

document.querySelectorAll('.islands-wrap').forEach(el => {
    new ScrollBooster({
        viewport: el,
        scrollMode: 'native',
        emulateScroll: true
    })
})

document.querySelectorAll('.carousel').forEach(el => {
    var booster = new ScrollBooster({
        viewport: document.querySelector('.carousel__wrapper'),
        scrollMode: 'native',
        emulateScroll: true
    })
    document.querySelector('.carousel__next').addEventListener('click', function (e) {
        var x = booster.getState().position.x + 100
        booster.scrollTo({ x: x, y: 0 })
    })
})